<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <section class="col-lg-12 connectedSortable table-area mt-3 pr-0 pl-0 mb-3 text-center">
                    <div class="card">
                        <div class="card-body table-responsive table-head pt-2 hvertical">
                            <div class="row">
                                <div class="col-md-8 text-left px-0 bor-bot pb-3">
                                    <h1 class="m-0 text-dark pt-2 text-left px-0">FAQ Sections</h1>
                                </div>
                                <div class="col-md-4 bor-bot pr-0">
                                    <div class="text-right pb-3 pt-1">
                                        <ol class="breadcrumb float-sm-right pr-0">
                                            <li class="breadcrumb-item"><a href="/admin/dashboard">Home</a></li>
                                            <li class="breadcrumb-item"><Breadcrumbs/></li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                              <custom-alert v-if="displayAlert==true" id="alert_box" :message="alertMessage" v-on:showedAlert="resetAlert"></custom-alert>
                                <div class="col-md-6 mt-3 showentries pl-0">
                                    <b-form inline>
                                        <label class="mr-sm-2 font-weight-normal">Show</label>
                                        <b-form-select
                                        id="inline-form-custom-select-pref"
                                        class="mb-2 mr-sm-2 mb-sm-0"
                                        v-model="perPage"
                                        :options="viewEntryOptions"
                                        size="sm"
                                        ></b-form-select
                                        >entries
                                    </b-form>
                                </div>
                                <div class="col-md-6 pr-0">
                                <div class="mt-3 mb-3 text-right pr-0 emltmpserchsec">
                                    <div class=" card-text d-flex align-items-center float-right mb-3"> 
                                        <div class="mr-4"> 
                                            <b-input-group size="sm">
                                                <b-form-input
                                                id="filter-input"
                                                v-model="filter"
                                                type="search"
                                                placeholder="Search"
                                                ></b-form-input>
                                            </b-input-group>
                                        </div>
                                        <div>
                                          <b-button
                                              variant="outline-primary" class="btn darkblubtn btn-outline-primary"
                                              @click="addRow($event.target)"
                                          >Add Sections</b-button
                                          >
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>

                            <b-table
                                ref="merchandiseTable"
                                id="merchandise-table"
                                :per-page="perPage"
                                :current-page="currentPage"
                                :items="items"
                                :fields="fields"
                                :busy="!loaded"
                                class="mt-3"
                                :filter="filter"
                                :filter-included-fields="filterOn"
                                @filtered="onFiltered"
                                hover
                                show-empty
                            >
                              <template #cell(section_name)="row" class="text-left">
                                <div class="text-left">{{ row.item.section_name }}</div>
                              </template>
                              <template #cell(actions)="row">
                                <div class="text-right py-0 align-middle">
                                  <div class="btn-group btn-group-sm">
                                    <button
                                        class="btn btn-primary ml-1"
                                        @click="editRow(row.item, $event.target)"
                                    >
                                      <i class="fas fa-edit"></i>
                                    </button>
                                    <button
                                        class="btn btn-danger ml-1 faq_trash"
                                        @click="deleteRow(row.item.id)"
                                    >
                                      <i class="fas fa-trash"></i>
                                    </button>
                                  </div>
                                </div>
                              </template>
                              <template #empty="scope">
                                <h4>{{ scope.emptyText }}</h4>
                              </template>
                              <template #table-busy>
                                <div class="text-center text-danger my-2">
                                  <b-spinner class="align-middle"></b-spinner>
                                  <strong>Loading...</strong>
                                </div>
                              </template>
                            </b-table>
                            <br />
                            <b-pagination
                                  v-if="loaded"
                                  v-model="currentPage"
                                  :total-rows="rows"
                                  :per-page="perPage"
                                  prev-text="Previous"
                                  next-text="Next"
                                  aria-controls="merchandise-table"
                                  class="float-right custom-color"
                                  hide-goto-end-buttons
                              ></b-pagination>

                          <!-- Edit Model -->
                          <b-modal
                              id="edit-modal"
                              :title="editModal.title"
                              size="lg"
                              ref="modal"
                              ok-title="Save"
                              @ok="handleOk"
                              @hide="resetEditModal"
                          >
                            <div class="row">
                              <div class="col-md-12">
                                <form ref="form" @submit.stop.prevent="handleSubmit">
                                  <div>
                                    <label class="pt-6 form-label" for="section_name"
                                    >Section Name</label
                                    >
                                    <b-form-input
                                        @keydown="errors.clear('section_name')"
                                        v-model="editModal.content.section_name"
                                        :class="errors.has('section_name') ? 'is-invalid' : ''"
                                        id="feedback-section_name"
                                    ></b-form-input>
                                    <b-form-invalid-feedback
                                        :state="!errors.has('section_name')"
                                    >
                                      {{ errors.get("section_name") }}</b-form-invalid-feedback
                                    >
                                  </div>
                                </form>
                              </div>
                            </div>
                          </b-modal>

                        </div>
                    </div>
                </section>
            </div>
        </div>
    </section>
</template>

<script>
  import Errors from "../../Errors";
  import moment from "moment";
  import axios from "axios";
  import CustomAlert from "../CustomAlert";

  let cachedData = {};
  export default {
    name:"FAQ Section",
    components:{
      CustomAlert
    },
    data() {
      return {
        loaded: false,
        perPage: 10,
        currentPage: 1,
        errors: new Errors(),
        fields: [
          {
            key: "section_name",
            label: "Section Name",
            thClass: "text-left",
            sortable: false,
          },
          {
            key: "actions",
            thClass: "text-right",
            sortable: false,
          },
        ],
        items: [],
        viewEntryOptions: [
          { value: 5, text: "5" },
          { value: 10, text: "10" },
          { value: 20, text: "20" },
        ],
        filter: null,
        filterOn: [],
        infoModal: {
          id: "info-modal",
          title: "",
          content: "",
        },
        editModal: {
          editing: true,
          id: "edit-modal",
          title: "",
          content: "",
        },
        alertMessage: "",
        displayAlert: false,
      }
    },
    filters: {
      productStatus(value) {
        return value ? "Active" : "Inactive";
      },
      formatDate(value) {
        if (value) {
          return moment(String(value)).format("MM/DD/YYYY");
        }
      },
    },

    methods: {
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      addRow(button) {
        (this.editModal.editing = false),
            (this.editModal.title = "Add New Section");
        let id = localStorage.getItem("id");
        this.editModal.content = {
          section_name: ""
        };
        this.$root.$emit("bv::show::modal", "edit-modal", button);
      },
      viewRow(item, index, button) {
        this.infoModal.title = item.name;
        this.infoModal.content = item;
        this.$root.$emit("bv::show::modal", this.infoModal.id, button);
      },
      editRow(item, button) {
        (this.editModal.editing = true),
            (this.editModal.title = "Update ");
        this.editModal.content = item;
        console.log(item);
        this.$root.$emit("bv::show::modal", "edit-modal", button);
      },
      deleteRow(id) {
        this.displayAlert = false;
        if(confirm("Are you sure, do you want to delete?")) {
          axios
              .delete(process.env.VUE_APP_URL + "api/faq/section/" + id, {headers: this.adminHeaders})
              .then((response) => {
                //alert("Deleted Successfully");
                this.displayAlert = true;
                this.alertMessage = "Deleted Successfully";
                this.resetEditModal();
              })
              .catch((error) => {
                (error) => (this.errors = error.response.data);
              });
        }
        return false;
      },

      //Modals
      resetInfoModal() {
        this.infoModal.title = "";
        this.infoModal.content = "";
      },
      resetEditModal() {
        this.errors.clear();
        this.loaded = false;
        axios
            .get(process.env.VUE_APP_URL + "api/faq/section", { headers:this.adminHeaders })
            .then((response) => {
              cachedData = response.data.data;
              this.items = cachedData;
              this.loaded = true;
            })
            .catch((error) => (this.errors = error.response.data));
        this.loaded = true;
      },

      // Submitting
      onFail(errors) {
        this.errors.record(errors);
      },

      handleOk(bvModalEvt) {
        bvModalEvt.preventDefault();
        let url = process.env.VUE_APP_URL + "api/faq/section";
        let requestType = "post";

        if (this.editModal.editing) {
          url = process.env.VUE_APP_URL + "api/faq/section/" + this.editModal.content.id;
        }

        this.handleSubmit(requestType, url, { headers:this.adminHeaders })
            .then(() => {
              this.$nextTick(() => {
                var message = this.editModal.editing ? "saved" : "created";
                //alert("Section has been " + message + ".");
                this.displayAlert = true;
                this.alertMessage = "Section has been " + message + ".";
                this.$bvModal.hide("edit-modal");
              });
            })
            .catch(() => false);
      },

      handleSubmit(requestType, url, headers) {
        return new Promise((resolve, reject) => {
          axios[requestType](url, this.editModal.content, headers)
              .then((response) => {
                resolve(response.data);
              })
              .catch((error) => {
                this.onFail(error.response.data.errors);
                reject(error.response.data);
              });
        });
      },
      resetAlert(){
        this.displayAlert = false;
      },
    },

    created() {
      if (Object.keys(cachedData).length === 0) {
        axios
            .get(process.env.VUE_APP_URL + "api/faq/section", { headers:this.adminHeaders })
            .then((response) => {
              cachedData = response.data.data;
              this.items = cachedData;
              this.loaded = true;
            })
            .catch((error) => (this.errors = error.response.data));
        return false;
      }
      this.items = cachedData;
      this.loaded = true;
    },
    mounted(){

    },
    computed: {
      rows() {
        return this.items.length;
      },
    },

  }
</script>